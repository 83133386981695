<template>
  <v-container fluid class="px-4 pt-0">
    <v-card class="mx-auto" max-width="1520" elevation="0">
      <AlertVue>
        <span class="font-weight-bold">ATENÇÃO:</span> Essa configuração não
        altera as permissões do Google Admin Console.
      </AlertVue>
      <v-row>
        <!-- MENU ESQUERDO -->
        <v-col cols="12" md="3" lg="3">
          <v-card :loading="showLoadingProducts" outlined>
            <v-card-title> Gerenciar permissões </v-card-title>

            <v-divider />

            <v-list nav class="pa-0" tile flat>
              <v-list-item
                :data-testid="`permission-menu-${appItem.title}`"
                link
                :class="`px-4 py-1 mb-0 ${
                  index + 1 !== appItems.length ? 'border-bottom' : ''
                }`"
                :active-class="'grey lighten-3 border-bottom'"
                :input-value="cardTitle === appItem.title"
                @click="changeCard(appItem)"
                v-for="(appItem, index) in appItems"
                :key="index"
                style="border-radius: 0px"
              >
                <v-list-item-avatar tile size="45">
                  <v-img
                    v-if="!!appItem.image"
                    :src="appItem.image"
                    contain
                  ></v-img>
                  <v-icon
                    v-else
                    v-text="appItem.icon"
                    size="40"
                    color="grey darken-1"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <h3 class="subtitle-1 font-weight-medium">
                    {{ appItem.title }}
                  </h3>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>

        <!-- CARD DE CONTEÚDO -->
        <v-col cols="12" md="9" lg="9">
          <v-card :loading="showLoading || showLoadingProducts" outlined>
            <v-row no-gutters align="center">
              <v-img
                v-if="!!cardImg"
                class="my-4 ml-4"
                height="40"
                max-width="40"
                :src="cardImg"
                contain
              ></v-img>
              <v-icon
                v-else
                v-text="cardIcon"
                class="my-4 ml-4"
                size="40"
                color="grey darken-1"
              />
              <v-card-title>{{ cardTitle }}</v-card-title>

              <v-spacer></v-spacer>

              <div>
                <v-play-video-button
                  title="Permissões de usuários"
                  id_youtube_video="SQvQdnXPDLQ"
                />
              </div>
              <v-btn
                data-testid="add-permission-btn"
                class="my-5 mx-5 text-none text-body-2 font-weight-medium"
                elevation="0"
                large
                color="accent"
                @click="dialog = true"
              >
                <v-icon left v-text="'mdi-shield-account'" size="1.4rem" />
                Adicionar gerente
              </v-btn>

              <!-- DIALOG: ATRIBUIR NOVO USUÁRIO -->

              <HeaderDialog
                id="add-admins"
                title="Adicionar gerente"
                color="primary"
                :show="dialog"
                :loading="showLoadingCard"
                width="600"
                showCancel
                button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
                :disabledAction="isInputEmpty"
                @close="cancelBtn"
                @action="updateUsers(getSelectedUsers, cardTitle, 'admin')"
              >
                <template v-slot:body>
                  <v-card-text class="mb-2 pa-0 text-body-1">
                    Selecione um usuário para adicionar a permissão de gerente.
                    Este usuário poderá acessar e gerenciar páginas de
                    <b>{{ cardTitle }}</b
                    >.
                  </v-card-text>

                  <v-card-actions
                    class="mt-4 mx-0 pa-0"
                    data-testid="add-user-permission-input"
                  >
                    <SelectUser
                      :items="filterAdminEmails"
                      :filter="customFilter"
                      label="Usuários selecionados"
                      className="mt-6"
                      color="accentSuite"
                      itemText="email"
                      itemValue="email"
                      activeClass="orange lighten-5"
                    ></SelectUser>
                  </v-card-actions>
                  <v-card-actions class="pa-0">
                    <AlertVue dense>
                      OBS: Essa configuração não altera as permissões do Google
                      Admin Console.
                    </AlertVue>
                  </v-card-actions>
                </template>
              </HeaderDialog>
            </v-row>
            <v-card-subtitle class="pt-0">{{ cardSubtitle }}</v-card-subtitle>

            <div v-if="problemGettingAdmin" align="center" justify="center">
              <v-icon size="65">mdi-laptop-off</v-icon>
              <v-card-text>Nenhuma informação encontrada</v-card-text>
            </div>

            <div v-else>
              <v-data-table
                :headers="headers"
                :items="items"
                :sort-by="['name']"
                :sort-desc="[false]"
                :items-per-page="itemsPerPage"
                :footer-props="{ itemsPerPageOptions: rowsPerPage }"
                locale="pt-BR"
                v-if="!showLoading"
                :height="tableHeight"
                fixed-header
              >
                <template v-slot:[`item.name`]="{ item }">
                  <v-row data-testid="users-permission-row" :class="item.email">
                    <v-col>
                      <v-avatar size="40" class="mr-4">
                        <img :src="item.image" />
                      </v-avatar>
                      <label>{{ item.name }}</label>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    :data-testid="`${item.email}-remove-permission-btn`"
                    text
                    small
                    :disabled="item.type == 'Administrador'"
                    color="accent"
                    class="font-weight-medium"
                    @click.stop="callDialogRemoveUser(item.email)"
                    >Remover</v-btn
                  >
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-row justify="center">
      <!-- DIALOG REMOVER USER -->
      <v-dialog v-model="dialogRemoveUser" width="500">
        <v-card>
          <h2 class="title py-3 px-6">Confirmar remoção</h2>
          <v-card-text
            >Você realmente deseja remover a permissão de
            {{ emailRemove }}?</v-card-text
          >
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="dialogRemoveUser = false"
              >Cancelar</v-btn
            >
            <v-btn
              data-testid="confirm-remove-permission-btn"
              :color="secondaryColor"
              text
              @click="updateUsers(emailRemove, cardTitle, 'collaborator')"
              >Confirmar</v-btn
            >
          </v-card-actions>
          <v-progress-linear
            v-if="showLoadingCard"
            indeterminate
            :color="primaryColor"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
      <!-- FIM DIALOG USER -->
    </v-row>
    <Snackbar
      data-testid="snackbar-permission"
      :show="showSnackbar"
      :message="snackbarText"
      :color="snackbarType"
      @close="showSnackbar = false"
    />
    <v-snackbar color="error" v-model="error404">
      {{ errorMessages.admin_function_sync }}
      <v-btn text>
        <a href="/users" style="color: #fff; text-decoration: none"
          >Sincronizar</a
        >
      </v-btn>
      <v-btn text @click="error404 = false">Fechar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { modules } from "@/helpers/variables/modules";
import { getModule } from "@/helpers/services/userPermissions";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { paging, tableHeight } from "@/helpers/variables/tablesDefault";
import AlertVue from "@/components/base/Alert.vue";
import SelectUser from "@/components/general/SelectUser.vue";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";

export default {
  name: "PageAdminFunctions",
  components: {
    AlertVue,
    SelectUser,
  },
  data() {
    return {
      errorMessages,
      tableHeight,
      showLoading: false,
      showLoadingProducts: false,
      error404: false,
      showLoadingCard: false,
      showSnackbar: false,
      snackbarType: "success",
      snackbarText: "",
      dialog: false,
      dialogRemoveUser: false,
      emailRemove: "",
      cardTitle: "",
      cardSubtitle: "",
      cardImg: null,
      cardIcon: null,
      appItems: [],
      problemGettingAdmin: false,
      headers: [
        { text: "Usuário", align: "start", value: "name" },
        { text: "E-mail", value: "email" },
        { text: "Tipo de permissão", width: 153, value: "type" },
        { text: "", value: "actions", sortable: false },
      ],
      items: [],
      emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+/.test(v) || "E-mail deve ser válido",
      ],
      itemsPerPage: paging.perPage,
      rowsPerPage: paging.All,
    };
  },

  computed: {
    ...mapGetters([
      "token",
      "contractedPlans",
      "users",
      "isReseller",
      "primaryColor",
      "secondaryColor",
      "currentUser",
      "hasConectaTrack",
      "getSelectedUsers",
    ]),

    isInputEmpty() {
      return this.getSelectedUsers.length == 0;
    },

    filterAdminEmails() {
      const adminEmails = this.items.map((item) => item.email);
      const filteredEmails = this.users.filter(
        (user) => !adminEmails.includes(user.email)
      );
      return filteredEmails;
    },
  },
  methods: {
    ...mapActions(["getContractedPlans", "getCurrentUser"]),
    ...mapMutations(["setSelectedUsers"]),
    getModule,

    async setAppItems() {
      this.showLoadingProducts = true;
      await this.getContractedPlans();

      let product_names = this.contractedPlans.reduce((acc, company_plan) => {
        const { products } = company_plan.plan;

        acc.push(...products.map((product) => product.name));
        return acc;
      }, []);

      if (this.isReseller) {
        product_names.push(modules.reseller);
      }

      product_names.push(modules.manageAdmins);
      product_names.push(modules.inventory);
      product_names.push(modules.companyProfile);
      product_names.push(modules.billing);
      product_names.push(modules.reports);

      new Set(product_names).forEach((product_name) => {
        if (product_name === modules.suite) {
          this.appItems.push({
            title: "Perfil do usuário",
            subtitle:
              "Ao conceder essa permissão, o usuário poderá compartilhar caixas de entrada entre membros da equipe, adicionar outros a grupos, criar e-mails secundários e aplicar assinaturas de e-mail e mensagens de férias individualmente.",
            code: modules.suite,
            image: require("@/assets/appslogos/icons/icon-user-account.png"),
            path: "/",
          });
        } else if (product_name === modules.track && this.hasConectaTrack) {
          this.appItems.push({
            title: "Conecta Track",
            code: modules.track,
            image: require("@/assets/appslogos/icons/icon-track.png"),
            path: "/",
          });
        } else if (product_name === modules.sign) {
          this.appItems.push({
            title: "Aplicação em massa",
            subtitle:
              "Ao conceder essa permissão, o usuário poderá aplicar assinaturas de e-mail e mensagem de férias para múltiplos usuários ao mesmo tempo. Criar e editar modelos de assinatura de e-mail e mensagem de férias, gerenciar informações dos usuários.",
            code: modules.sign,
            image: require("@/assets/appslogos/icons/icon-account-group.png"),
            path: "/",
          });
        } else if (product_name === modules.transfer) {
          this.appItems.push({
            title: "Conecta Transfer",
            code: modules.transfer,
            image: require("@/assets/appslogos/icons/icon-transfer.png"),
            path: "/",
          });
        } else if (product_name === modules.support) {
          this.appItems.push({
            title: "Conecta Support",

            code: modules.support,
            image: require("@/assets/appslogos/icons/icon-support.png"),
            path: "/",
          });
        } else if (product_name === modules.reseller) {
          this.appItems.push({
            title: "Conecta Partners",
            subtitle:
              "Ao conceder essa permissão, o usuário poderá visualizar e gerenciar as informações dos clientes, incluindo dados de contato e detalhes administrativos. (Exclusivo para parceiros)",
            code: modules.reseller,
            icon: "mdi-bank",
            path: "/",
          });
        } else if (product_name === modules.manageAdmins) {
          this.appItems.push({
            title: "Permissões de Usuários",
            subtitle:
              "Ao conceder essa permissão, o usuário poderá gerenciar permissões de uso das funcionalidades e recursos do Conecta Suite para outros membros da empresa",
            code: modules.manageAdmins,
            icon: "mdi-account-key",
            path: "/",
          });
        } else if (product_name === modules.companyProfile) {
          this.appItems.push({
            title: "Perfil da Empresa",
            subtitle:
              "Ao conceder essa permissão, o usuário poderá visualizar e atualizar as informações da empresa",
            code: modules.companyProfile,
            icon: "mdi-store",
            path: "/",
          });
        } else if (product_name === modules.inventory) {
          this.appItems.push({
            title: "Inventário digital",
            subtitle:
              "Ao conceder essa permissão, o usuário poderá visualizar e gerenciar aplicativos conectados ao e-mail corporativo, além de incluir itens, valores e quantidades no inventário digital da empresa.",
            code: modules.inventory,
            icon: "mdi-application",
            path: "/",
          });
        } else if (product_name === modules.billing) {
          this.appItems.push({
            title: "Faturamento",
            subtitle:
              "Ao conceder essa permissão, o usuário poderá gerenciar os planos contratados pela empresa, acompanhando o histórico de cobranças e faturas.",
            code: modules.billing,
            icon: "mdi-credit-card-outline",
            path: "/",
          });
        } else if (product_name === modules.reports) {
          this.appItems.push({
            title: "Relatórios",
            subtitle:
              "Ao conceder essa permissão, o usuário poderá acessar e visualizar os registros de todas as operações realizadas no Conecta Suite.",
            code: modules.reports,
            icon: "mdi-poll",
            path: "/",
          });
        }
      });
      if (this.appItems.length) {
        this.cardTitle = this.appItems[0].title;
        this.cardSubtitle = this.appItems[0].subtitle;
        this.cardImg = this.appItems[0].image;
        this.cardIcon = this.appItems[0].icon;
        this.getSpecialUserByModule(this.appItems[0].code);
      }
      this.showLoadingProducts = false;
    },

    callDialogRemoveUser(email) {
      this.emailRemove = email;
      this.dialogRemoveUser = true;
    },

    getSpecialUserByModule(product) {
      this.showLoading = true;

      this.$axios
        .get(process.env.VUE_APP_API_BASE_URL + "/admin-users", {
          params: { product: product },
          headers: {
            Authorization: this.token,
          },
        })
        .then(({ data }) => {
          let formatedArray = [];
          for (let i = 0; i < data.length; i++) {
            formatedArray.push({
              name: data[i].name,
              email: data[i].email,
              type: data[i].is_google_admin ? "Administrador" : "Gerente",
              image: data[i].photo,
            });
          }
          this.items = formatedArray;
          this.showLoading = false;
        })
        .catch((error) => {
          this.items = "";
          this.showLoading = false;
          this.problemGettingAdmin = true;
          if (error.response.request.status == 404) {
            this.error404 = true;
          } else {
            this.snackbarText = errorMessages.unknown;
            this.showSnackbar = true;
            this.snackbarType = "error";
          }
        });
    },

    async updateUsers(users_email, nameModule, permission) {
      this.showLoadingCard = true;
      let product = getModule(nameModule);
      let body = {
        product: product,
        permission: permission,
        modify_user: users_email,
      };
      await this.callAPI(body);
    },

    async callAPI(body) {
      let auth = { headers: { Authorization: this.token } };
      await this.$axios
        .put(`${process.env.VUE_APP_API_BASE_URL}/admin-users`, body, auth)
        .then(() => {
          if (typeof body.modify_user === "string") {
            body.modify_user = [body.modify_user];
          }
          body.modify_user.forEach((item) => {
            if (item === this.currentUser.email) {
              this.getCurrentUser();
            }
          });
          this.getSpecialUserByModule(body.product);
          this.snackbarText = successMessages.operation_sucess;
          this.showSnackbar = true;
          this.snackbarType = "success";
        })
        .catch((error) => {
          if (error.response.request.status == 403) {
            this.snackbarText = errorMessages.admin_function_403_error;
            this.showSnackbar = true;
            this.snackbarType = "error";
          } else if (error.response.request.status == 409) {
            this.snackbarText = errorMessages.admin_function_409_error;
            this.showSnackbar = true;
            this.snackbarType = "warning";
          } else {
            this.snackbarText = errorMessages.errorMessages.unknown;
            this.showSnackbar = true;
            this.snackbarType = "error";
          }
        })
        .finally(() => {
          this.showLoadingCard = false;
          this.dialog = false;
          this.dialogRemoveUser = false;
          this.emailRemove = "";
        });
    },

    cancelBtn() {
      this.dialog = false;
      this.setSelectedUsers([]);
    },

    customFilter(item, queryText) {
      const textOne = item.email.toLowerCase().trim();
      const textTwo = item.name.toLowerCase().trim();
      const searchText = queryText.toLowerCase().trim();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    changeCard({ title, subtitle, code, image, icon }) {
      this.cardTitle = title;
      this.cardSubtitle = subtitle;
      this.cardImg = image;
      this.cardIcon = icon;
      this.getSpecialUserByModule(code);
    },
  },

  beforeMount() {
    this.setAppItems();
  },
};
</script>
